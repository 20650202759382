import React, { useEffect, useMemo, useState } from 'react'
import './App.scss'

import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { ScrollToTop } from './components/ScrollToTop'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsalAuthentication,
} from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import ApolloWrapper from './components/ApolloWrapper'
import {
    DevSettingsContext,
    DevSettingsContextType,
} from './utils/DevSettingsContext'
import Routes from './Routes'
import { FormaButton } from './components/FormaButton'

function App() {
    useMsalAuthentication(InteractionType.Redirect)
    const { login } = useMsalAuthentication(InteractionType.Popup);
    const handleLogin = () => {
        login()
    };
    const storedDevSettingsContext: DevSettingsContextType = JSON.parse(
        localStorage.getItem('devSettingsContext') || '{}'
    )

    const [isDeveloper, setIsDeveloper] = useState<boolean>(
        storedDevSettingsContext.isDeveloper || false
    )
    const [hideDevDisclaimer, setHideDevDisclaimer] = useState<boolean>(
        storedDevSettingsContext.hideDevDisclaimer || false
    )
    const [spoofEmailAddress, setSpoofEmailAddress] = useState<boolean>(
        storedDevSettingsContext.spoofEmailAddress || false
    )
    const [fakeEmailAddress, setFakeEmailAddress] = useState<
        string | undefined
    >(storedDevSettingsContext.fakeEmailAddress)

    const [showNotificationsEarlyAccess, setShowNotificationsEarlyAccess] =
        useState<boolean>(
            storedDevSettingsContext.showNotificationsEarlyAccess || false
        )

    const devSettingsContext: DevSettingsContextType = useMemo(() => {
        return {
            isDeveloper,
            setIsDeveloper,
            hideDevDisclaimer,
            setHideDevDisclaimer,
            spoofEmailAddress,
            setSpoofEmailAddress,
            fakeEmailAddress,
            setFakeEmailAddress,
            showNotificationsEarlyAccess,
            setShowNotificationsEarlyAccess,
        }
    }, [
        isDeveloper,
        hideDevDisclaimer,
        spoofEmailAddress,
        fakeEmailAddress,
        showNotificationsEarlyAccess,
    ])

    useEffect(() => {
        localStorage.setItem(
            'devSettingsContext',
            JSON.stringify(devSettingsContext)
        )
    }, [devSettingsContext])

    return (
        <Router>
            <React.Fragment>
                <ScrollToTop />
                <AuthenticatedTemplate>
                    <DevSettingsContext.Provider value={devSettingsContext}>
                        <ApolloWrapper>
                            <SnackbarProvider maxSnack={3}>
                                <Routes />
                            </SnackbarProvider>
                        </ApolloWrapper>
                    </DevSettingsContext.Provider>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <FormaButton onButtonClick={handleLogin} />
                </UnauthenticatedTemplate>
            </React.Fragment>
        </Router>
    )
}

export default App