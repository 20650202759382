import { keyframes, Stack, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { Logo } from './Logo';

const StyledButton = styled('button')(({ theme }) => ({
  borderRadius: '20px',
  padding: '10px 20px',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontSize: '16px',
  fontWeight: 'bold',
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

interface FormaButtonProps {
  onButtonClick: () => void;
}

export function FormaButton({ onButtonClick }: FormaButtonProps) {
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsButtonVisible(true);
    }, 2500); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Stack
      height="100vh"
      width="100vw"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: '#ffffff',
        // animation: `${loadingEffect} 1s alternate infinite`,
      }}
    >
      <Logo width={300} height={150} />
      {isButtonVisible && (
        <StyledButton onClick={onButtonClick}>Click to login</StyledButton>
      )}
    </Stack>
  );
}